// @ts-strict-ignore
import { useTranslation } from "react-i18next";

import { useGlobalConfig } from "@unauthenticated/shared/context/GlobalConfig/GlobalConfig";
import { l } from "@unauthenticated/shared/locales/i18n";

/**
 * Use `useTreatmentPlanTerm` instead of typing `prescription` in a translation file.
 * This is required b/c some prac modalities can't legally use the terms prescription and have to use recommendation instead.
 * useTreatmentPlanTerm automatically updates the terminology based on their selection.
 * Practitioners can also change it at any time under the Preferences tab in Account Settings in the app.
 */
const useTreatmentPlanTerm = (
  options: { plural?: boolean; capitalize?: boolean; recommended?: boolean } = {}
): string => {
  const { treatmentPlanTerm } = useGlobalConfig();
  const { t } = useTranslation();

  let key = `${treatmentPlanTerm ?? "prescription"}`;

  if (options.recommended) {
    key += "_past_participle";
  }

  if (options.capitalize) {
    key += "_capitalized";
  }

  return t(l.common[key], { count: options.plural ? 100 : 1 });
};

export { useTreatmentPlanTerm };
