// @ts-strict-ignore
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelPasswordProps } from "@aviary";
import { FloatingLabelPassword } from "@aviary";
import { l } from "@unauthenticated/shared/locales/i18n";

interface Props extends Omit<FloatingLabelPasswordProps, "id" | "label"> {
  errors?: ReactNode[];
  value: string;
  isDirty: boolean;
  isLoading: boolean;
  isCredentialsIncorrect: boolean;
}

const SignInPasswordInput = ({
  isDirty,
  value,
  errors,
  disabled,
  isLoading,
  isCredentialsIncorrect,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const isPasswordLength = !!value;

  const getErrors = () => {
    if (!isDirty) return null;
    if (!isPasswordLength) return [t(l.signIn.PasswordIsRequired)];
    if (isCredentialsIncorrect) return [t(l.signIn.IncorrectEmailOrPassword)];

    return [...(errors || [])];
  };

  return (
    <FloatingLabelPassword
      autoComplete="on"
      id="password"
      name="password"
      label={t(l.common.Password)}
      value={value}
      errors={getErrors()}
      disabled={disabled || isLoading}
      {...rest}
    />
  );
};
export { SignInPasswordInput };
