import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { Roleable, TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated";

const UserSignIn_UnauthenticatedShared_Mutation = gql`
  mutation UserSignIn_UnauthenticatedShared_Mutation($input: AuthUserSignInInput!) {
    auth {
      userSignIn(input: $input) {
        user {
          id
          roles {
            id
            type
          }
          twoFactorAuthentication {
            id
            isEnabled
          }
        }
        twoFactorAuthenticationToken
        otpMethod
        userEmail
        emailMasked
        phoneLast4
        redirectPath
        errors {
          message
        }
      }
    }
  }
`;

type Role = Pick<Roleable, "id" | "type">;

interface AuthUserSignInInput {
  email: string;
  password: string;
  staySignedIn?: boolean;
  rememberMe?: boolean;
  optional?: any;
  intake?: boolean;
}

interface Variables {
  input: {
    attributes: AuthUserSignInInput;
    captchaToken: string;
  };
}

interface UserSignInError {
  message: string;
}

interface TwoFactorAuthentication {
  id: string;
  isEnabled: boolean;
}

interface User {
  id: string;
  roles: Role[];
  twoFactorAuthentication: TwoFactorAuthentication;
}

interface UserSignInMutationData {
  auth: {
    userSignIn: {
      user: User;
      redirectPath: string;
      twoFactorAuthenticationToken: string;
      otpMethod: TwoFactorAuthenticationMethods;
      userEmail: string;
      emailMasked: string;
      phoneLast4: string;
      errors: UserSignInError;
    };
  };
}

const useUserSignIn = (options?: MutationHookOptions<UserSignInMutationData, Variables>) =>
  useMutation<UserSignInMutationData, Variables>(
    UserSignIn_UnauthenticatedShared_Mutation,
    options
  );

export type { Variables, UserSignInMutationData };

export { UserSignIn_UnauthenticatedShared_Mutation, useUserSignIn };
