import type { ReactNode } from "react";
import { Trans } from "react-i18next";

import { Link, Message, Typography } from "@aviary";
import { SsoErrorPage } from "@unauthenticated/shared/components/SSOErrorBox/types/ssoErrorPage";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { useTreatmentPlanTerm } from "@unauthenticated/shared/hooks/useTreatmentPlanTerm/useTreatmentPlanTerm";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./SSOErrorBox.styles";

interface Props {
  ssoErrorPage?: SsoErrorPage;
  ssoErrors: string;
  onRedirect?: () => void;
}

const SSOErrorBox = ({ ssoErrorPage, ssoErrors, onRedirect }: Props) => {
  const treatmentPlanTerm = useTreatmentPlanTerm();

  if (!ssoErrors) return null;

  const getPracSignUpLink = () => {
    if (ssoErrorPage === SsoErrorPage.OAUTH) {
      return (
        <Link onClick={onRedirect}>
          <Trans i18nKey={l.ssoError.signUp}>sign up</Trans>
        </Link>
      );
    }

    return (
      <Link to={authRoutes.practitioner_sign_up}>
        <Trans i18nKey={l.ssoError.signUp}>sign up</Trans>
      </Link>
    );
  };

  const getErrorTranslation = (errorText: string): { title?: ReactNode; message: ReactNode } => {
    switch (errorText) {
      case "apple/an_account_already_exists":
      case "google/an_account_already_exists":
        return {
          message: (
            <Trans i18nKey={l.ssoError.signUpEmailAlreadyExist}>
              An account with this email exists. Please{" "}
              <Link onClick={onRedirect}>
                <Trans i18nKey={l.ssoError.signIn}>sign in</Trans>
              </Link>
            </Trans>
          ),
        };

      case "apple/no_user_info_found":
        return {
          title: (
            <Trans i18nKey={l.ssoError.appleAccountCantConnectHeader}>
              Unable to connect Apple account
            </Trans>
          ),
          message: (
            <Trans i18nKey={l.ssoError.appleAccountCantConnectMessage}>
              Please use your email and password to sign in.
            </Trans>
          ),
        };

      case "apple/rx_email_mismatch":
      case "google/rx_email_mismatch":
        return {
          message: (
            <Trans i18nKey={l.ssoError.rxSignInWrongEmail}>
              The email you selected is not associated with this {{ treatmentPlanTerm }}
            </Trans>
          ),
        };

      case "apple/rx_not_found":
      case "google/rx_not_found":
        return {
          message: (
            <Trans i18nKey={l.ssoError.invalidEmailLink}>
              This link is invalid. Click on the link in the email to try again.
            </Trans>
          ),
        };

      case "apple/user_not_found":
        return {
          title: (
            <Trans i18nKey={l.ssoError.signInEmailDoesntExistTitle}>
              Please try another email, or {getPracSignUpLink()}
            </Trans>
          ),
          message: (
            <Trans i18nKey={l.ssoError.signInAppleEmailDoesntExistMessage}>
              Your Apple ID isn't connected to a Fullscript account.
            </Trans>
          ),
        };

      case "google/user_not_found":
        return {
          title: (
            <Trans i18nKey={l.ssoError.signInEmailDoesntExistTitle}>
              Please try another email, or {getPracSignUpLink()}
            </Trans>
          ),
          message: (
            <Trans i18nKey={l.ssoError.signInGoogleEmailDoesntExistMessage}>
              Your Google email isn't connected to a Fullscript account.
            </Trans>
          ),
        };

      case "apple/user_not_invited":
      case "google/user_not_invited":
        return {
          title: (
            <Trans i18nKey={l.ssoError.officeSignUpWrongEmailTitle}>
              The email you selected hasn't received an invitation.
            </Trans>
          ),
          message: (
            <Trans i18nKey={l.ssoError.officeSignUpWrongEmailMessage}>
              To join the dispensary, you must use the same email address from your invite.
            </Trans>
          ),
        };
      default:
        return {
          message: <>{errorText}</>,
        };
    }
  };

  const errorMessage = getErrorTranslation(ssoErrors);

  return (
    <Message
      css={styles.messageContainer}
      isColor="danger"
      titleContent={errorMessage?.title}
      data-testid="sso-error-message"
    >
      <Typography>{errorMessage.message}</Typography>
    </Message>
  );
};

export { SSOErrorBox };
