import { createContext, useContext } from "react";
import type { ReactNode } from "react";

import { EMAIL_REGEX } from "./emailRegex";

interface GlobalContextType {
  treatmentPlanTerm: string;
  emailRegex: RegExp;
}

const Default: GlobalContextType = {
  treatmentPlanTerm: "",
  emailRegex: EMAIL_REGEX,
};

const GlobalContext = createContext<GlobalContextType>(Default);

const useGlobalConfig = () => {
  const { emailRegex, treatmentPlanTerm } = useContext(GlobalContext);

  return { emailRegex, treatmentPlanTerm };
};

interface Props {
  treatmentPlanTerm: string;
  children: ReactNode;
}

const GlobalContextProvider = ({ children, treatmentPlanTerm }: Props) => {
  return (
    <GlobalContext value={{ treatmentPlanTerm, emailRegex: EMAIL_REGEX }}>{children}</GlobalContext>
  );
};

export { GlobalContextProvider, useGlobalConfig };
