// @ts-strict-ignore
const getActionURL = (
  provider: "Google" | "Apple",
  formData,
  additionalAttributes,
  signUpPage,
  treatmentPlanSlug,
  labRecommendationId,
  storeSlug,
  pracTypeId,
  pageVersion,
  uuid,
  isIntake,
  requestedPractitionerId
) => {
  const {
    firstName: customFirstName,
    lastName: customLastName,
    storeName,
    tosAcceptedAt,
  } = formData || {};
  const {
    referralCode,
    referralPartnerId,
    referralCodeId,
    micrositeId,
    redirectPath,
    signupSource,
    apiClientId,
    isOauth,
    source,
    peerReferralCode,
    peerReferrerSlug,
    utmSource,
    utmCampaign,
    utmMedium,
    utmId,
    utmContent,
    utmTerm,
    roleId,
    roleType,
  } = additionalAttributes || {};

  const urlSearchParams = new URLSearchParams();
  if (customFirstName) urlSearchParams.set("custom_first_name", customFirstName);
  if (customLastName) urlSearchParams.set("custom_last_name", customLastName);
  if (storeName) urlSearchParams.set("store_name", storeName);
  if (tosAcceptedAt) urlSearchParams.set("tos_accepted_at", tosAcceptedAt);
  if (pageVersion) urlSearchParams.set("page_version", pageVersion);
  if (signUpPage) urlSearchParams.set("sign_up_page", signUpPage);
  if (treatmentPlanSlug) urlSearchParams.set("treatment_plan_slug", treatmentPlanSlug);
  if (storeSlug) urlSearchParams.set("store_slug", storeSlug);
  if (pracTypeId) urlSearchParams.set("practitioner_type_id", pracTypeId);
  if (referralCode) urlSearchParams.set("referral_code", referralCode);
  if (referralPartnerId) urlSearchParams.set("referral_partner_id", referralPartnerId);
  if (referralCodeId) urlSearchParams.set("referral_code_id", referralCodeId);
  if (micrositeId) urlSearchParams.set("microsite_id", micrositeId);
  if (peerReferralCode) urlSearchParams.set("peer_referral_code", peerReferralCode);
  if (peerReferrerSlug) urlSearchParams.set("peer_referrer_slug", peerReferrerSlug);
  if (redirectPath) urlSearchParams.set("redirect_path", redirectPath);
  if (signupSource) urlSearchParams.set("signup_source", signupSource);
  if (apiClientId) urlSearchParams.set("api_client_id", apiClientId);
  if (isOauth) urlSearchParams.set("is_oauth", isOauth);
  if (source) urlSearchParams.set("source", source);
  if (uuid) urlSearchParams.set("uuid", uuid);
  if (utmSource) urlSearchParams.set("utm_source", utmSource);
  if (utmCampaign) urlSearchParams.set("utm_campaign", utmCampaign);
  if (utmMedium) urlSearchParams.set("utm_medium", utmMedium);
  if (utmTerm) urlSearchParams.set("utm_term", utmTerm);
  if (utmId) urlSearchParams.set("utm_id", utmId);
  if (utmContent) urlSearchParams.set("utm_content", utmContent);
  if (roleType) urlSearchParams.set("role_type", roleType);
  if (roleId) urlSearchParams.set("role_id", roleId);
  if (isIntake) urlSearchParams.set("intake", "true");
  if (requestedPractitionerId)
    urlSearchParams.set("requested_practitioner_id", requestedPractitionerId);

  let baseURL;

  if (provider === "Google") {
    baseURL = `/user/auth/google_oauth2`;
  } else if (provider === "Apple") {
    baseURL = `/user/auth/apple`;
  }

  return `${baseURL}?${urlSearchParams.toString()}`;
};

export { getActionURL };
