import { sharedCommon } from "@shared/locales/en/US";

export const common = {
  ...sharedCommon,
  HaveAcount: "Already have an account?",
  WhereAreYouLocated: "Where are you located?",
  SelectYourAccountType: "Select your account type.",
  HaveExistingEmersonAccount:
    "You have an existing Emerson Ecologics account and it will be used to access Fullscript.",
  SignIn: "Sign in",
  EmailAlreadyExists: "An account with this email exists, <2>sign in to continue</2>",
  LengthValidation: "Must be at least 8 characters long",
  LengthConfirmation: "Password is 8+ characters long",
  FirstName: "First name",
  LastName: "Last name",
  DispensaryName: "Dispensary name",
  DesignationSearch: "Search for your health profession",
  DesignationNoResults: "No results found",
  FirstNameRequired: "First name is required",
  FirstNameInvalid: "First name can't be a url",
  DispensaryNameDefaultWithPlatform: "{{- fullName}} {{storePlatform}} Account",
  DispensaryNameDefault: "{{- fullName}} Fullscript Account",
  DispensaryDisplayName: "{{- fullName }} Dispensary",
  DispensaryNameInvalid: "Dispensary name can't be a url",
  DispensaryNameTooShort: "Dispensary name is too short",
  EditOnceSignedIn: "You can edit this information once you are signed in",
  LastNameRequired: "Last name is required",
  LastNameInvalid: "Last name can't be a url",
  CreateMyAccount: "Create my account",
  CreateAccount: "Create account",
  IAmA: "I am a ...",
  IAmAPatient: "I'm a patient,",
  FindInvite: "find my invite.",
  Password: "Password",
  TermsOfServiceAndPrivacyStatement:
    "By creating your account you agree to our <2>Terms of Service</2> and <4>Privacy Statement</4>",
  TermsOfServiceWithExistingEmersonAccount: "By activating your account you agree to our ",
  Or: "Or",
  WeRequireType: "Required so we can tailor your experience accordingly",
  Search: "Search...",
  PasswordPrompt: "Input your password to continue",
  emailInUse: "This email is already in use. Try <1>sign in</1> or <3>forgot password</3> instead",
  Terms: "I agree to {{platform}} <2>{{terms}}</2> and <4>{{privacy}}</4>",
  Fullscript: "Fullscript's",
  EmersonEcologics: "Emerson Ecologics'",
  Emerson: {
    DispensaryName: "Business name",
    DispensaryNameInvalid: "Business name can't be a url",
    DispensaryNameTooShort: "Business name is too short",
  },
  PracAcccount: "Practitioner account",
  PatientAccount: "Patient account",
  RecommendSupplementsAndLabs: "Recommend supplements and labs to your patients.",
  OrderSupplementsAndLabs: "Order supplements, labs, and track your results.",
  GetStartedWithFs: "Get started with Fullscript",
  WhichTypeOfAccount: "Which type of account would you like to create?",
  Next: "Next",
  privacyPolicy: "Privacy Policy",
  privacyStatement: "Privacy Statement",
  termsOfSale: "Terms of Sale",
  termsOfServices: "Terms of Service",
  practitionerSignupStepper: {
    step1: "Get started",
    step2: "Create account",
    step3: "Practice info",
    step4: "Credentials",
  },
} as const;
